import { StepComponentData } from '../hooks/useSimulationFee'
import { SelectItem } from '../interfaces/common'
import { SimulationState } from '../interfaces/simulations'

export const IS_ACTIVITY_ITEM: SelectItem[] = [
  { label: '', value: '' },
  { label: `Activités agricoles (inclus pêches et élevages)`, value: '1' },
  { label: `Activité de petit exploitant minier`, value: '2' },
  { label: `Actvité de Transport non motorisé`, value: '3' },
  { label: `Artisans`, value: '4' },
  { label: `Petits producteurs locaux`, value: '5' },
  { label: `Petits commerçants/gargotiers`, value: '6' },
  { label: `Artisans miniers`, value: '7' },
  { label: `Artistes et assimilés`, value: '8' },
  { label: `Commerçants`, value: '9' },
  { label: `Activité hôtelière et restauration`, value: '10' },
  { label: `Activité Touristique`, value: '11' },
  { label: `Prestataires services`, value: '12' },
  { label: `Professions libérales`, value: '13' },
  { label: `Autres secteurs / activités multiples`, value: '14' },
]

export const IR_ACTIVITY_ITEM: SelectItem[] = [
  { label: '', value: '' },
  { label: `Activité agricole (inclus pêches et élevages)`, value: '1' },
  { label: `Activité artisanale`, value: '2' },
  { label: `Activité industrielle`, value: '3' },
  { label: `Activité minière`, value: '4' },
  { label: `Activité hôtelière`, value: '5' },
  { label: `Activité de la Santé`, value: '6' },
  { label: `Activité de l'Enseignement`, value: '7' },
  { label: `Activité Touristique`, value: '8' },
  { label: `Activité de Transport`, value: '9' },
  { label: `Autres secteurs / activités multiples`, value: '10' },
]

export const IS_ACTIVITY_FEE_MAP: Map<string, number> = new Map<string, number>(
  [
    ['1', 16000],
    ['2', 16000],
    ['3', 16000],
    ['4', 50000],
    ['5', 50000],
    ['6', 50000],
    ['7', 100000],
    ['8', 100000],
    ['9', 100000],
    ['10', 100000],
    ['11', 100000],
    ['12', 100000],
    ['13', 150000],
    ['14', 150000],
  ],
)

export const IR_ACTIVITY_FEE_MAP: Map<string, number> = new Map<string, number>(
  [
    ['1', 100000],
    ['2', 100000],
    ['3', 100000],
    ['4', 100000],
    ['5', 100000],
    ['6', 100000],
    ['7', 100000],
    ['8', 100000],
    ['9', 100000],
    ['10', 320000],
  ],
)

export const INIT_SIMULATION_STATE: SimulationState = {
  statusFormId: '',
  activityTypeId: '',
  capital: 0,
  mounthlyBail: 0,
  bailDuration: 0,
  nbAssociate: 1,
  nbAssociateMoralPerson: 0,
  impositionChoice: '',
  isStatutoryManager: '',
  activityAmount: 0,
  statusAmount: 0,
  bailleurType: '',
  isMandataire: '',
}

export const INIT_STEP_COMPONENT: StepComponentData = {
  id: '',
  stepDescritpion_fr: '',
  stepTitle_fr: '',
  fieldComponent: [],
}
