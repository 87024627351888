import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useSelector } from 'react-redux'
import useSimulationFee from '../../../../hooks/useSimulationFee'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
    },
    padding: `${theme.spacing(2)}px 0px`,
  },
  jusitfy: {
    textAlign: 'justify',
  },
}))

const FeeHeader = () => {
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const data = useSimulationFee()
  // console.log('!!', data)
  const title = getInternationalizedValue(
    data.introductionParagraph,
    'title',
    language,
  )
  const descriptionTxt = getInternationalizedValue(
    data.introductionParagraph,
    'description',
    language,
  )

  return (
    <div>
      <Typography variant="h4">{title}</Typography>
      <div className={classes.description}>
        <Typography variant="body1" className={classes.jusitfy}>
          <TextMarkdown text={descriptionTxt} />
        </Typography>
      </div>
    </div>
  )
}

export default FeeHeader
