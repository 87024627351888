import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import React, { FC, useEffect, useState } from 'react'
import { FormProps } from '../../../interfaces/form'
import { formatNumber } from '../../../utils/common'
import { isValidNumber } from '../../../utils/validation'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& input:disabled': {
      color: '#CCC',
    },
  },
}))

interface CustomNumberFieldProps {
  value: string
  minValue?: number
  maxValue?: number
  beginTyping?: boolean
  handleChange(payload: FormProps): void
}

const CustomNumberField: FC<
  TextFieldProps & CustomNumberFieldProps
> = props => {
  const { label, minValue, maxValue, disabled } = props
  const isMinValueError =
    typeof minValue !== 'undefined'
      ? minValue > +props.value.replace(/ /g, '')
      : false
  const isMaxValueError =
    typeof maxValue !== 'undefined'
      ? maxValue < +props.value.replace(/ /g, '')
      : false
  const isError = isMinValueError || isMaxValueError || !props.value.length
  const helperText = isError ? props.helperText || '' : undefined

  const classes = useStyles()
  const [beginTyping, setBeginTyping] = useState(props.beginTyping || false)

  useEffect(() => {
    setBeginTyping(props.beginTyping || false)
  }, [props.beginTyping])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event

    setBeginTyping(true)

    if (!value.length) {
      props.handleChange({ name, value })
    } else {
      const trimed = value.replace(/ /g, '')
      // Manual validation because type="number" does not work sometimes
      if (isValidNumber(trimed)) {
        props.handleChange({ name, value: formatNumber(+trimed) || '' })
      }
    }
  }

  return (
    <TextField
      {...props}
      className={classes.root}
      label={label}
      variant="outlined"
      name={props.name}
      value={props.value}
      onChange={handleChange}
      fullWidth={true}
      // type="number"
      // InputProps={{ inputProps: { min: minValue, max: maxValue } }}
      error={beginTyping && isError}
      helperText={beginTyping && isError && helperText}
      disabled={disabled}
    />
  )
}

export default CustomNumberField
