import { LEGAL_STATUS_DICT } from '../constants/submitForm'
import { NumberItem } from '../interfaces/common'
import {GIEfeeArgs, OtherStatusfeeArgs, SAfeeArgs} from '../interfaces/simulations'
import { formatNumber } from './common'

const calculateExtraitRcs = (
  nbAssociateMoralPerson: number,
  bailleurType: number,
): number => 2000 * (nbAssociateMoralPerson + bailleurType)

const EXTRAIT_RCS_LABEL = `Edition Extrait RCS personne morale`

export const SAfees = ({
  capital,
  bailDuration,
  mounthlyBail,
  nbAssociate,
  nbAssociateMoralPerson,
  bailleurType,
  statusFormId,
}: SAfeeArgs): NumberItem[] => {
  const fees: NumberItem[] = []
  fees.push({
    label: `Enregistrement Statut (${LEGAL_STATUS_DICT[statusFormId]})`,
    value: 2000,
  })
  const bulletinAmount = 2000 * nbAssociate
  fees.push({
    label: `Enregistrement Bulletin de souscription pour ${nbAssociate} associé(s)`,
    value: bulletinAmount,
  })
  fees.push({
    label: `Déclaration de souscription et de versement`,
    value: 2000,
  })
  // fees.push({ label: `Attestation de dépôt`, value: 2000 })
  fees.push({ label: `Etat de souscription`, value: 2000 })

  const bailAmount = mounthlyBail * bailDuration
  let bailAmountPercent = (bailAmount * 2) / 100
  bailAmountPercent = bailAmountPercent > 10000 ? bailAmountPercent : 10000
  fees.push({
    label: `Enregistrement contrat de bail (2% x Montant total bail: ${formatNumber(
      bailAmount,
    )})`,
    value: bailAmountPercent,
  })

  if (capital > 0) {
    let pvAmount = (capital * 0.5) / 100

    pvAmount = pvAmount > 10000 ? pvAmount : 10000
    fees.push({
      label: `Procès-verbal d'approbation de constitution définitive (0,5% x Montant Capital: ${formatNumber(
        capital,
      )})`,
      value: pvAmount,
    })
  }


  const extraitRcsAmount = calculateExtraitRcs(
    nbAssociateMoralPerson,
    bailleurType,
  )
  if (extraitRcsAmount) {
    fees.push({
      label: EXTRAIT_RCS_LABEL,
      value: extraitRcsAmount,
    })
  }
  return fees
}

export const OtherStatusfees = ({
  capital,
  bailDuration,
  mounthlyBail,
  isStatutoryManager,
  nbAssociateMoralPerson,
  bailleurType,
}: OtherStatusfeeArgs): NumberItem[] => {
  const fees: NumberItem[] = []
  let statusAmount = (capital * 0.5) / 100
  statusAmount = statusAmount > 10000 ? statusAmount : 10000
  fees.push({
    label: `Enregistrement des statuts ou contrat (0,5% x Montant Capital: ${formatNumber(
      capital,
    )})`,
    value: statusAmount,
  })

  const bailAmount = mounthlyBail * bailDuration
  let bailAmountPercent = (bailAmount * 2) / 100
  bailAmountPercent = bailAmountPercent > 10000 ? bailAmountPercent : 10000
  fees.push({
    label: `Enregistrement contrat de bail (2% x Montant total bail: ${formatNumber(
      bailAmount,
    )})`,
    value: bailAmountPercent,
  })

  if (!isStatutoryManager) {
    fees.push({
      label: `Enregistrement PV nomination de gérants`,
      value: 2000,
    })
  }

  const extraitRcsAmount = calculateExtraitRcs(
    nbAssociateMoralPerson,
    bailleurType,
  )
  if (extraitRcsAmount) {
    fees.push({
      label: EXTRAIT_RCS_LABEL,
      value: extraitRcsAmount,
    })
  }

  return fees
}

export const GIEfees = ({bailDuration, monthlyAmount}: GIEfeeArgs): NumberItem[] => {
  const fees: NumberItem[] = [];
  const bailAmount = monthlyAmount * bailDuration;
  let bailAmountPercent = (bailAmount * 2) / 100;
  bailAmountPercent = bailAmountPercent > 10000 ? bailAmountPercent : 10000;
  fees.push({
    label: `Enregistrement contrat de bail (2% x Montant total bail: ${formatNumber(
      bailAmount,
    )})`,
    value: bailAmountPercent,
  })

  fees.push({
    label: `Procès-verbal d'approbation de constitution définitive`,
    value: 2000,
  })
  return fees
}

export const SDETfees = (): NumberItem[] => {
  const fees: NumberItem[] = []
  fees.push({
    // label: `PV d'ouverture succursale/bureau de liaison/Agence`,
    label: `PV d'ouverture succursale`,
    value: 2000,
  })
  return fees
}

export const getCompanyFees = (): NumberItem[] => {
  const fees: NumberItem[] = []
  fees.push({ label: `Immatriculation RCS`, value: 16000 })
  fees.push({ label: `Immatriculation Statistique`, value: 40000 })
  return fees
}

export const displaySimulationStep = (
  index: number,
  step: number,
  isSA: boolean,
  isSAorSAU: boolean,
  isUnipersonnel: boolean,
): boolean => {
  // Comment because too hard to implement yet
  // if (step > SimulationStepEnum.STATUS) {
  //   if (
  //     !isSAorSAU &&
  //     step !== SimulationStepEnum.ASSOCIATE &&
  //     step !== SimulationStepEnum.MANAGER
  //   ) {
  //     return (
  //       index !== SimulationStepEnum.ASSOCIATE &&
  //       index !== SimulationStepEnum.MANAGER &&
  //       step >= index
  //     )
  //   } else {
  //     // For unipersonnal company, display stepper if it is not ASSOCIATE step and current step greater than or equal index
  //     if (isUnipersonnel && step !== SimulationStepEnum.ASSOCIATE) {
  //       return index !== SimulationStepEnum.ASSOCIATE && step >= index
  //     }
  //   }
  // }
  return step >= index
}
