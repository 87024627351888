import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import React, {FC, useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import CustomGroupRadio from '../../../../../components/forms/customGroupRadio'
import CustomNumberField from '../../../../../components/forms/customNumberField'
import CustomSelect from '../../../../../components/forms/customSelect'
import {
  BAILLEUR_TYPE_ITEMS,
  MANDATAIRE_ITEMS,
  YES_NO_ITEMS,
} from '../../../../../constants/common'
import { IMPOSITION_CHOICE_ITEMS } from '../../../../../constants/submitForm'
import { SimulationStepEnum } from '../../../../../enum/simulation'
import { LegalStatusEnum } from '../../../../../enum/submitForm'
import useAllLegalStatus from '../../../../../hooks/useAllLegalStatus'
import { StepComponentData } from '../../../../../hooks/useSimulationFee'
import { SelectItem } from '../../../../../interfaces/common'
import { FormProps } from '../../../../../interfaces/form'
import { SimulationState } from '../../../../../interfaces/simulations'
import { RootState } from '../../../../../redux/store'
import { getInternationalizedValue } from '../../../../../utils/common'
import { getStatusFormItem } from '../../../../../utils/submitForm'
import TextMarkdown from '../../../../customMarkdown/textMarkdown'
import NavigationButton from '../navigationButton'

const MIN_BAIL_AMOUNT = 1
const MIN_BAIL_DURATION = 3

interface StepContainerProps {
  index: number
  step: number
  simulationState: SimulationState
  activityListItems: SelectItem[]
  isSA: boolean
  isSAorSAU: boolean
  isSDET: boolean
  isUnipersonnel: boolean
  stepComponent: StepComponentData
  handleCommonFormChange(props: FormProps): void
  handleImpositionChange(props: FormProps): void
  handleActivityChange(props: FormProps): void
  handleStatusFormChange(props: FormProps): void
  handleSetStep: (newStep: number) => () => void
}

const StepContainer: FC<StepContainerProps> = props => {
  const {
    index,
    step,
    simulationState,
    activityListItems,
    isSA,
    isSAorSAU,
    isSDET,
    isUnipersonnel,
    stepComponent,
    handleCommonFormChange,
    handleImpositionChange,
    handleActivityChange,
    handleStatusFormChange,
    handleSetStep,
  } = props

  const { language } = useSelector((state: RootState) => state.app)
  const data = useAllLegalStatus()
  const filteredStatusForm = getStatusFormItem(data, language)
  const isCurrentIndex = step === index
  const [minNbAssociate, setMinNbAssociate] = useState(1)

  useEffect(() => {
    if (simulationState.statusFormId === LegalStatusEnum.SA_CA) {
      setMinNbAssociate(4)
    } else if (simulationState.statusFormId === LegalStatusEnum.SDET) {
      setMinNbAssociate(0)
    } else if (
      simulationState.statusFormId === LegalStatusEnum.SARLU ||
      simulationState.statusFormId === LegalStatusEnum.SAU
    ) {
      setMinNbAssociate(1)
    } else {
      setMinNbAssociate(2)
    }
  }, [simulationState.statusFormId])

  const stepDescription = getInternationalizedValue(
    stepComponent,
    'stepDescritpion',
    language,
  )

  const displayAssociate = (isSA || !isUnipersonnel) && !isSDET
  let nextStep = undefined
  let prevStep = undefined

  switch (index) {
    case SimulationStepEnum.IMPOSITION:
      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}

          <CustomGroupRadio
            items={IMPOSITION_CHOICE_ITEMS}
            name="impositionChoice"
            // label="Choix du Régime d'imposition"
            label=""
            value={simulationState.impositionChoice}
            isMandatory={true}
            handleChange={handleImpositionChange}
            isHorizontal={true}
            disabled={!isCurrentIndex}
          />
          {isCurrentIndex ? (
            <NavigationButton
              hidePrevious={true}
              disableNext={!simulationState.impositionChoice.length}
              handleNext={handleSetStep(SimulationStepEnum.ACTIVITY)}
            />
          ) : null}
        </Box>
      )
    case SimulationStepEnum.ACTIVITY:
      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}
          <CustomSelect
            items={activityListItems}
            name="activityTypeId"
            label=""
            value={simulationState.activityTypeId}
            handleChange={handleActivityChange}
            disabled={!isCurrentIndex}
          />
          {isCurrentIndex ? (
            <NavigationButton
              disableNext={!simulationState.activityTypeId.length}
              handlePrevious={handleSetStep(SimulationStepEnum.IMPOSITION)}
              handleNext={handleSetStep(SimulationStepEnum.STATUS)}
            />
          ) : null}
        </Box>
      )
    case SimulationStepEnum.STATUS:
      // Ask associate for SA or not unipersonnel and not SDET
      /* (isSA || !isUnipersonnel) && !isSDET */
      if (displayAssociate) {
        nextStep = SimulationStepEnum.ASSOCIATE
      } else if (isSA) {
        nextStep = SimulationStepEnum.MANAGER
      } else if (
        simulationState.statusFormId === LegalStatusEnum.SARLU ||
        simulationState.statusFormId === LegalStatusEnum.SAU
      ) {
        nextStep = SimulationStepEnum.MORAL_PERSON_ASSOCIATE
      } else {
        nextStep = SimulationStepEnum.CAPITAL
      }
      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}
          <CustomSelect
            items={filteredStatusForm}
            name="statusFormId"
            label=""
            value={simulationState.statusFormId}
            handleChange={handleStatusFormChange}
            disabled={!isCurrentIndex}
          />
          {isCurrentIndex ? (
            <NavigationButton
              disableNext={!simulationState.statusFormId.length}
              handlePrevious={handleSetStep(SimulationStepEnum.ACTIVITY)}
              handleNext={handleSetStep(nextStep)}
            />
          ) : null}
        </Box>
      )
    case SimulationStepEnum.ASSOCIATE:
      if (simulationState.statusFormId === LegalStatusEnum.GIE) {
        nextStep = SimulationStepEnum.CAPITAL;
      } else {
        nextStep = SimulationStepEnum.MORAL_PERSON_ASSOCIATE;
      }

      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}
          <CustomNumberField
            label=""
            name="nbAssociate"
            value={simulationState.nbAssociate.toString()}
            handleChange={handleCommonFormChange}
            minValue={minNbAssociate}
            placeholder={`Valeur minimum: ${minNbAssociate}`}
            disabled={!isCurrentIndex}
          />
          {isCurrentIndex ? (
            <NavigationButton
              disableNext={!`${simulationState.nbAssociate}`.length}
              handlePrevious={handleSetStep(SimulationStepEnum.STATUS)}
              handleNext={handleSetStep(nextStep)}
            />
          ) : null}
        </Box>
      )
    case SimulationStepEnum.MORAL_PERSON_ASSOCIATE:
      if (
        simulationState.statusFormId === LegalStatusEnum.SARLU ||
        simulationState.statusFormId === LegalStatusEnum.SAU
      ) {
        prevStep = SimulationStepEnum.STATUS
      } else {
        prevStep = SimulationStepEnum.ASSOCIATE
      }
      if (
        simulationState.statusFormId !== LegalStatusEnum.SARL &&
        simulationState.statusFormId !== LegalStatusEnum.SARLU
      ) {
        nextStep = SimulationStepEnum.CAPITAL
      } else {
        nextStep = SimulationStepEnum.MANAGER
      }
      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}
          <CustomNumberField
            label=""
            name="nbAssociateMoralPerson"
            value={`${simulationState.nbAssociateMoralPerson}`}
            handleChange={handleCommonFormChange}
            minValue={0}
            placeholder={`Valeur minimum: 0`}
            disabled={!isCurrentIndex}
          />
          {isCurrentIndex ? (
            <NavigationButton
              disableNext={
                !simulationState.nbAssociateMoralPerson.toString().length ||
                simulationState.nbAssociateMoralPerson > simulationState.nbAssociate
              }
              handlePrevious={handleSetStep(prevStep)}
              handleNext={handleSetStep(nextStep)}
            />
          ) : null}
        </Box>
      )
    case SimulationStepEnum.MANAGER:
      if (
        displayAssociate ||
        simulationState.statusFormId === LegalStatusEnum.SARL ||
        simulationState.statusFormId === LegalStatusEnum.SARLU
      ) {
        prevStep = SimulationStepEnum.MORAL_PERSON_ASSOCIATE
      } else {
        prevStep = SimulationStepEnum.STATUS
      }
      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}
          <CustomGroupRadio
            isHorizontal={true}
            items={YES_NO_ITEMS}
            name="isStatutoryManager"
            label=""
            value={simulationState.isStatutoryManager}
            handleChange={handleCommonFormChange}
            disabled={!isCurrentIndex}
          />
          {isCurrentIndex ? (
            <NavigationButton
              disableNext={!`${simulationState.isStatutoryManager}`.length}
              handlePrevious={handleSetStep(prevStep)}
              handleNext={handleSetStep(SimulationStepEnum.CAPITAL)}
            />
          ) : null}
        </Box>
      );
    case SimulationStepEnum.CAPITAL:
      prevStep = simulationState.isStatutoryManager
        ? SimulationStepEnum.MANAGER
        : SimulationStepEnum.STATUS; // Go immediatly to status form choice if not SA or SAU

      if (simulationState.statusFormId === LegalStatusEnum.GIE) {
        prevStep = SimulationStepEnum.ASSOCIATE
      }

      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}
          <CustomNumberField
            label=""
            name="capital"
            value={`${simulationState.capital}`}
            handleChange={handleCommonFormChange}
            minValue={0}
            disabled={!isCurrentIndex}
          />
          {isCurrentIndex ? (
            <NavigationButton
              disableNext={!`${simulationState.capital}`.length}
              handlePrevious={handleSetStep(prevStep)}
              handleNext={handleSetStep(SimulationStepEnum.BAIL_AMOUNT)}
            />
          ) : null}
        </Box>
      )
    case SimulationStepEnum.BAIL_AMOUNT:
      const disableAmountNext =
        `${simulationState.mounthlyBail}`.length === 0
          ? true
          : simulationState.mounthlyBail < MIN_BAIL_AMOUNT

      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}
          <CustomNumberField
            label=""
            name="mounthlyBail"
            value={`${simulationState.mounthlyBail}`}
            handleChange={handleCommonFormChange}
            minValue={1}
            disabled={!isCurrentIndex}
            placeholder="Valeur minimum: 1"
          />
          {isCurrentIndex ? (
            <NavigationButton
              disableNext={disableAmountNext}
              handlePrevious={handleSetStep(SimulationStepEnum.CAPITAL)}
              handleNext={handleSetStep(SimulationStepEnum.BAIL_DURATION)}
            />
          ) : null}
        </Box>
      )
    case SimulationStepEnum.BAIL_DURATION:
      const disableDurationNext =
        `${simulationState.bailDuration}`.length === 0
          ? true
          : simulationState.bailDuration < MIN_BAIL_DURATION
      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}
          <CustomNumberField
            label=""
            name="bailDuration"
            value={`${simulationState.bailDuration}`}
            handleChange={handleCommonFormChange}
            minValue={3}
            disabled={!isCurrentIndex}
            placeholder="Valeur minimum: 3"
          />
          {isCurrentIndex ? (
            <NavigationButton
              disableNext={disableDurationNext}
              handlePrevious={handleSetStep(SimulationStepEnum.BAIL_AMOUNT)}
              handleNext={handleSetStep(SimulationStepEnum.BAILLEUR_TYPE)}
            />
          ) : null}
        </Box>
      )

    case SimulationStepEnum.BAILLEUR_TYPE:
      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}
          <CustomGroupRadio
            isHorizontal={true}
            items={BAILLEUR_TYPE_ITEMS}
            name="bailleurType"
            label=""
            value={simulationState.bailleurType}
            handleChange={handleCommonFormChange}
            disabled={!isCurrentIndex}
          />
          {isCurrentIndex ? (
            <NavigationButton
              disableNext={!`${simulationState.bailleurType}`.length}
              handlePrevious={handleSetStep(SimulationStepEnum.BAIL_DURATION)}
              handleNext={handleSetStep(SimulationStepEnum.IS_MANDATAIRE)}
            />
          ) : null}
        </Box>
      )

    default:
      // IS_MANDATAIRE
      return (
        <Box mt={1}>
          {stepDescription ? (
            <div>
              <Typography variant="subtitle1">
                <TextMarkdown text={stepDescription} />
              </Typography>
            </div>
          ) : null}
          <CustomGroupRadio
            isHorizontal={true}
            items={MANDATAIRE_ITEMS}
            name="isMandataire"
            label=""
            value={simulationState.isMandataire}
            handleChange={handleCommonFormChange}
          />
          <NavigationButton
            hideNext={true}
            handlePrevious={handleSetStep(SimulationStepEnum.BAILLEUR_TYPE)}
          />
        </Box>
      )
  }
}

export default StepContainer
