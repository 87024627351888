import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'
import { Table, Tbody, Td, Tr } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { NumberItem } from '../../../../interfaces/common'
import { SimulationState } from '../../../../interfaces/simulations'
import {
  formatNumber,
  getInternationalizedValue,
} from '../../../../utils/common'
import { getCompanyFees } from '../../../../utils/simulation'
import TextMarkdown from '../../../customMarkdown/textMarkdown'
import { useSelector } from 'react-redux'
import useSimulationFee from '../../../../hooks/useSimulationFee'
import { RootState } from '../../../../redux/store'

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    margin: `${theme.spacing(2)}px 0px`,
    '& table': {
      'border-spacing': '0px',
      fontFamily: 'Aeonik',
      '& thead': {
        '& tr': {
          '& th': {
            borderBottom: 'solid #C5C5C5 2px',
          },
        },
      },
      '& tbody': {
        '& tr': {
          border: 'none',
          [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(4), // Fix overflow while long title for mobile screen
          },
          '& td': {
            textAlign: 'right',
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          },
          '& td.pivoted': {
            paddingLeft: `${theme.spacing(2)}px !important`,
          },
          '& td:first-child': {
            textAlign: 'left',
          },
        },
        '& tr:nth-child(odd)': {
          backgroundColor: '#F5F5F5',
        },
      },
    },
  },
  textBold: {
    fontWeight: 'bold',
  },
  jusitfy: {
    textAlign: 'justify',
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontWeight: 'bold',
    margin: theme.spacing(2),
  },
}))

interface TableResultProps {
  simulationState: SimulationState
  activityAmount: number
  statusFeeList: NumberItem[]
  total: number
  totalRef: any
}

const TableResult: FC<TableResultProps> = props => {
  const {
    simulationState,
    activityAmount,
    statusFeeList,
    total,
    totalRef,
  } = props

  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const data = useSimulationFee()

  const tableTitle = getInternationalizedValue(data, 'recapMessage', language)

  return (
    <>
      <Typography
        variant="h5"
        color="primary"
        style={{ textAlign: 'justify', fontWeight: 'bold' }}
      >
        <TextMarkdown text={tableTitle} />
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        fontWeight="bold"
      >
        <Box minWidth="360px" my={1}>
          <Typography className={classes.textBold}>Libellés</Typography>
        </Box>
        <Box my={1}>
          <Typography className={classes.textBold}>
            Montant en Ariary
          </Typography>
        </Box>
      </Box>
      <div className={classes.tableContainer}>
        <Table>
          <Tbody>
            <>
              {getCompanyFees().map((f, i) => (
                <Tr key={`company-fee-${i}`}>
                  <Td>
                    <Typography variant="body2">{f.label}</Typography>
                  </Td>
                  <Td>
                    <Typography variant="body1">
                      {formatNumber(f.value)}
                    </Typography>
                  </Td>
                </Tr>
              ))}
              {Boolean(simulationState.activityTypeId) ? (
                <Tr>
                  <Td>
                    <Typography variant="body2">
                      Acompte dû uniquement pour début d'activité
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="body1">
                      {formatNumber(activityAmount)}
                    </Typography>
                  </Td>
                </Tr>
              ) : null}
              {Boolean(simulationState.statusFormId) ? (
                <>
                  {/* {isSAorSAU ||
                    Boolean(simulationState.isStatutoryManager) ? (
                      <> */}
                  {statusFeeList.map((f, i) => (
                    <Tr key={`status-form-fee-${i}`}>
                      <Td>
                        <Typography variant="body2">{f.label}</Typography>
                      </Td>
                      <Td>
                        <Typography variant="body1">
                          {formatNumber(f.value)}
                        </Typography>
                      </Td>
                    </Tr>
                  ))}
                  {/* </>
                    ) : null} */}
                </>
              ) : null}
              {simulationState.isMandataire === '1' ? (
                <Tr>
                  <Td>
                    <Typography variant="body1">Procuration</Typography>
                  </Td>
                  <Td>
                    <Typography variant="body2">
                      {formatNumber(2000)}
                    </Typography>
                  </Td>
                </Tr>
              ) : null}
            </>
          </Tbody>
        </Table>
        <Divider />
        <div ref={totalRef} className={classes.total}>
          <Typography className={classes.textBold}>TOTAL</Typography>
          <Typography className={classes.textBold}>
            {formatNumber(total)}
          </Typography>
        </div>
      </div>
    </>
  )
}

export default TableResult
