import { Button, Theme } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import ArrowForwerdIcon from '@material-ui/icons/ArrowForward'
import { makeStyles } from '@material-ui/styles'
import React, { ComponentProps, FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))

const NextButton: FC<ComponentProps<any> & ButtonProps> = props => {
  const { children } = props

  const classes = useStyles()

  return (
    <Button
      {...props}
      variant="contained"
      className={classes.root}
      endIcon={<ArrowForwerdIcon />}
      color="primary"
    >
      {children}
    </Button>
  )
}

export default NextButton
