import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useRef, useState } from 'react'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import FeeFooter from '../components/pages/simulationFee/feeFooter'
import FeeHeader from '../components/pages/simulationFee/feeHeader'
import Questions from '../components/pages/simulationFee/questions'
import TableResult from '../components/pages/simulationFee/tableResult'
import SEO from '../components/seo'
import {
  INIT_SIMULATION_STATE,
  IR_ACTIVITY_FEE_MAP,
  IR_ACTIVITY_ITEM,
  IS_ACTIVITY_FEE_MAP,
  IS_ACTIVITY_ITEM,
} from '../constants/simulation'
import { LegalStatusEnum } from '../enum/submitForm'
import { NumberItem, SelectItem } from '../interfaces/common'
import { FormProps } from '../interfaces/form'
import { removeSpaceOfNumber } from '../utils/common'
import {
  getCompanyFees,
  GIEfees,
  OtherStatusfees,
  SAfees,
  SDETfees,
} from '../utils/simulation'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      // padding: `${theme.spacing(12)}px 0px`,
      margin: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.up('md')]: {
      margin: `0px ${theme.spacing(7)}px 0px ${theme.spacing(7)}px`,
      // padding: `${theme.spacing(12)}px ${theme.spacing(2)}px`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('lg')]: {
      margin: `0px ${theme.spacing(15)}px 0px ${theme.spacing(15)}px`,
      // padding: `${theme.spacing(12)}px ${theme.spacing(2)}px`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}))

const SimulationFee = () => {
  const classes = useStyles()

  const [simulationState, setSimulationState] = useState({
    ...INIT_SIMULATION_STATE,
  })

  const [activityListItems, setActivityListItems] = useState<SelectItem[]>([])
  const [activityAmount, setActivityAmount] = useState(0)
  const [statusFeeList, setStatusFeeList] = useState<NumberItem[]>([])

  const totalRef = useRef<any>(null)
  const headerRef = useRef<any>(null)

  const [step, setStep] = useState(0)

  useEffect(() => {
    let activityData: Map<string, number> | undefined = undefined
    if (simulationState.impositionChoice === '0') {
      setActivityListItems(IS_ACTIVITY_ITEM)
      activityData = IS_ACTIVITY_FEE_MAP
    }
    if (simulationState.impositionChoice === '1') {
      setActivityListItems(IR_ACTIVITY_ITEM)
      activityData = IR_ACTIVITY_FEE_MAP
    }

    if (activityData) {
      if (activityData.get(simulationState.activityTypeId)) {
        setActivityAmount(activityData.get(simulationState.activityTypeId) || 0)
      }
    }
  }, [simulationState.impositionChoice, simulationState.activityTypeId])

  const isSA =
    simulationState.statusFormId === LegalStatusEnum.SA_AG ||
    simulationState.statusFormId === LegalStatusEnum.SA_CA

  const isSAorSAU = isSA || simulationState.statusFormId === LegalStatusEnum.SAU

  const isUnipersonnel =
    simulationState.statusFormId === LegalStatusEnum.SAU ||
    simulationState.statusFormId === LegalStatusEnum.SARLU ||
    simulationState.statusFormId === LegalStatusEnum.EI

  const isSDET = simulationState.statusFormId === LegalStatusEnum.SDET

  const isMandataire = simulationState.isMandataire === '1'
  const mandataireFee = isMandataire ? 2000 : 0

  useEffect(() => {
    const statusFormId = simulationState.statusFormId
    const isStatutoryManager = simulationState.isStatutoryManager
    const capital = simulationState.capital
      ? +removeSpaceOfNumber(`${simulationState.capital}`)
      : 0
    const bailDuration = simulationState.bailDuration
      ? +removeSpaceOfNumber(`${simulationState.bailDuration}`)
      : 0
    const mounthlyBail = simulationState.mounthlyBail
      ? +removeSpaceOfNumber(`${simulationState.mounthlyBail}`)
      : 0
    const nbAssociate = simulationState.nbAssociate
      ? +removeSpaceOfNumber(`${simulationState.nbAssociate}`)
      : 1
    const nbAssociateMoralPerson = simulationState.nbAssociateMoralPerson
      ? +removeSpaceOfNumber(`${simulationState.nbAssociateMoralPerson}`)
      : 0
    const bailleurType = simulationState.bailleurType
      ? +simulationState.bailleurType
      : 0

    if (statusFormId) {
      if (isSAorSAU) {
        setStatusFeeList(
          SAfees({
            capital,
            bailDuration,
            mounthlyBail,
            nbAssociate,
            nbAssociateMoralPerson,
            bailleurType,
            statusFormId,
          }),
        )
      } else if (statusFormId === LegalStatusEnum.GIE) {
        setStatusFeeList(GIEfees({bailDuration, monthlyAmount: mounthlyBail}))
      } else if (statusFormId === LegalStatusEnum.SDET) {
        setStatusFeeList(SDETfees())
      } else {
        // if (Boolean(isStatutoryManager)) {
        const isStatutory = isStatutoryManager
          ? Boolean(+isStatutoryManager)
          : false
        setStatusFeeList(
          OtherStatusfees({
            capital,
            bailDuration,
            mounthlyBail,
            isStatutoryManager: isStatutory,
            nbAssociateMoralPerson,
            bailleurType,
          }),
        )
        // }
        if (
          statusFormId === LegalStatusEnum.SARL &&
          simulationState.nbAssociate < 2
        ) {
          handleCommonFormChange({ name: 'nbAssociate', value: 2 })
        }
      }
    }
  }, [
    isSAorSAU,
    simulationState.statusFormId,
    simulationState.capital,
    simulationState.bailDuration,
    simulationState.bailDuration,
    simulationState.mounthlyBail,
    simulationState.nbAssociate,
    simulationState.nbAssociateMoralPerson,
    simulationState.bailleurType,
    simulationState.isStatutoryManager,
  ])

  useEffect(() => {
    if (isUnipersonnel) {
      handleCommonFormChange({ name: 'nbAssociate', value: 1 })
    }
  }, [isUnipersonnel])

  const total =
    activityAmount +
    statusFeeList.reduce((acc, s) => acc + s.value, 0) +
    getCompanyFees().reduce((acc, f) => acc + f.value, 0) +
    mandataireFee

  const handleCommonFormChange = ({ name, value }: FormProps) => {
    if (name === 'isMandataire' && `${value}`.length) {
      if (totalRef && totalRef.current) {
        totalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }

    if (name === 'nbAssociate' && value.toString().length && simulationState.statusFormId === LegalStatusEnum.GIE) {
      setSimulationState({
        ...simulationState,
        nbAssociate: parseInt(value.toString()),
        nbAssociateMoralPerson: parseInt(value.toString()),
      })
    } else {
      setSimulationState({...simulationState, [name]: value})
    }
  }

  const handleImpositionChange = ({ name, value }: FormProps) => {
    setSimulationState({
      ...simulationState,
      [name]: value,
      activityTypeId: '',
      statusFormId: '',
    })
    setStatusFeeList([])
  }

  const handleActivityChange = ({ name, value }: FormProps) => {
    setSimulationState({ ...simulationState, [name]: value })
  }

  const handleStatusFormChange = ({ name, value }: FormProps) => {
    let nbAssociate = 2
    if (value === LegalStatusEnum.SA_CA) {
      nbAssociate = 4
    } else if (value === LegalStatusEnum.SDET) {
      nbAssociate = 0
    } else if (
      value === LegalStatusEnum.SARLU ||
      value === LegalStatusEnum.SAU
    ) {
      nbAssociate = 1
    }
    setSimulationState({
      ...simulationState,
      [name]: value,
      nbAssociate,
      isStatutoryManager: '',
      nbAssociateMoralPerson: value === LegalStatusEnum.GIE ? nbAssociate : 0,
    })
  }

  const handleReset = () => {
    setSimulationState({ ...INIT_SIMULATION_STATE })
    setStep(0)
    setStatusFeeList([])
    setActivityAmount(0)
    setActivityListItems([])
    if (headerRef && headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const handleSetStep = (newStep: number) => () => {
    setStep(newStep)
  }

  return (
    <>
      <SEO title="Simulation Droit" />
      <div className={classes.container}>
        <div ref={headerRef}>
          <FeeHeader />
        </div>
        <div>
          <Questions
            activityListItems={activityListItems}
            isSA={isSA}
            isSAorSAU={isSAorSAU}
            isUnipersonnel={isUnipersonnel}
            isSDET={isSDET}
            simulationState={simulationState}
            step={step}
            handleActivityChange={handleActivityChange}
            handleCommonFormChange={handleCommonFormChange}
            handleImpositionChange={handleImpositionChange}
            handleStatusFormChange={handleStatusFormChange}
            handleSetStep={handleSetStep}
          />

          <Box my={2}>
            <Divider />
          </Box>
          <TableResult
            activityAmount={activityAmount}
            simulationState={simulationState}
            statusFeeList={statusFeeList}
            total={total}
            totalRef={totalRef}
          />

          <Box display="flex" justifyContent="center" my={2}>
            <Button variant="contained" color="primary" onClick={handleReset}>
              Recommencer
            </Button>
          </Box>

          <Box mb={2}>
            <FeeFooter />
          </Box>
        </div>
      </div>
    </>
  )
}

export default SimulationFee
