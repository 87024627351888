import { graphql, useStaticQuery } from 'gatsby'

export interface StatusData {
  id: number
  legal_status_mg: string
  legal_status_fr: string
  legal_status_en: string
  acronym_legal_status_mg: string
  acronym_legal_status_fr: string
  acronym_legal_status_en: string
  associate_mg: string
  associate_fr: string
  associate_en: string
  project_definition_mg: string
  project_definition_fr: string
  project_definition_en: string
  note_mg: string
  note_fr: string
  note_en: string
  articles: {
    id: number
  }
}

export interface LegalStatusData {
  id: string
  STATUS: string
  Status: StatusData
  priority: string
}

export default (): LegalStatusData[] => {
  const data = useStaticQuery(graphql`
    {
      allStrapiLegalStatusLists(filter: {Status: {legal_status_fr: {regex: "/\\w/"}}}) {
        nodes {
          id
          STATUS
          priority
          Status {
            id
            legal_status_mg
            legal_status_fr
            legal_status_en
            acronym_legal_status_mg
            acronym_legal_status_fr
            acronym_legal_status_en
            associate_mg
            associate_fr
            associate_en
            project_definition_mg
            project_definition_fr
            project_definition_en
            note_mg
            note_fr
            note_en
            articles {
              id
            }
          }
        }
      }
    }
  `)

  return data.allStrapiLegalStatusLists.nodes
}
