import { Step, StepContent, StepLabel, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Stepper from '@material-ui/core/Stepper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { INIT_STEP_COMPONENT } from '../../../../constants/simulation'
import useSimulationFee from '../../../../hooks/useSimulationFee'
import { SelectItem } from '../../../../interfaces/common'
import { FormProps } from '../../../../interfaces/form'
import { SimulationState } from '../../../../interfaces/simulations'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import { displaySimulationStep } from '../../../../utils/simulation'
import StepContainer from './stepContainer'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      // padding: `${theme.spacing(12)}px 0px`,
      margin: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      margin: `0px ${theme.spacing(7)}px 0px ${theme.spacing(7)}px`,
      // padding: `${theme.spacing(12)}px ${theme.spacing(2)}px`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('lg')]: {
      margin: `0px ${theme.spacing(15)}px 0px ${theme.spacing(15)}px`,
      // padding: `${theme.spacing(12)}px ${theme.spacing(2)}px`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  fieldsContainer: {
    margin: `${theme.spacing(2)}px 0px`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '48%',
  },
  radioContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '48%',
  },
  textBold: {
    fontWeight: 'bold',
  },
  stepLabel: {
    '& svg': {
      '& text': {
        fill: 'white',
      },
    },
  },
}))

interface QuestionsProps {
  simulationState: SimulationState
  activityListItems: SelectItem[]
  isSA: boolean
  isSAorSAU: boolean
  isSDET: boolean
  isUnipersonnel: boolean
  step: number
  handleCommonFormChange(props: FormProps): void
  handleImpositionChange(props: FormProps): void
  handleActivityChange(props: FormProps): void
  handleStatusFormChange(props: FormProps): void
  handleSetStep: (newStep: number) => () => void
}

const Questions: FC<QuestionsProps> = props => {
  const {
    simulationState,
    activityListItems,
    isSA,
    isSAorSAU,
    isSDET,
    isUnipersonnel,
    step,
    handleCommonFormChange,
    handleImpositionChange,
    handleActivityChange,
    handleStatusFormChange,
    handleSetStep,
  } = props
  const classes = useStyles()

  // const submitFormData: SubmitFormData = useSubmitForm()
  // const { Step1, Step2, step3 } = submitFormData

  // const step1Title = getInternationalizedValue(Step1, 'title', language)
  // const step2Title = getInternationalizedValue(Step2, 'title', language)
  // const step3Title = getInternationalizedValue(step3, 'title', language)

  const { language } = useSelector((state: RootState) => state.app)

  const data = useSimulationFee()

  // const steps = [
  //   'Veuillez choisir le régime d’imposition que vous envisagez',
  //   `Veuillez choisir le type d'activité de votre société`,
  //   'Veuillez choisir la forme juridique que vous souhaitez pour votre société',
  //   'Merci de préciser le nombre d’associés',
  //   'Les dirigeants (Gérant/Co-gérant) sont-ils nommés dans les statuts de la société ?',
  //   'Veuillez saisir le montant total en Ariary du capital en numéraire',
  //   'Veuillez saisir le montant total en Ariary de votre bail mensuel, charges comprises',
  //   'Veuillez saisir la durée de votre contrat de bail (en mois)',
  //   'Allez-vous effectuer les démarches vous-même ou aurez-vous recours à un mandataire ?',
  // ]

  const steps = data.stepComponent.map(s =>
    getInternationalizedValue(s, 'stepTitle', language),
  )

  return (
    <Box>
      <Stepper activeStep={step} orientation="vertical">
        {steps.map((label, index) => (
          // <Step key={label} active={step >= index}>
          <Step
            key={label}
            active={displaySimulationStep(
              index,
              step,
              isSA,
              isSAorSAU,
              isUnipersonnel,
            )}
          >
            <StepLabel className={classes.stepLabel}>
              <Typography variant="h5">{label}</Typography>
            </StepLabel>
            <StepContent>
              <StepContainer
                activityListItems={activityListItems}
                simulationState={simulationState}
                index={index}
                step={step}
                isSA={isSA}
                isUnipersonnel={isUnipersonnel}
                isSAorSAU={isSAorSAU}
                isSDET={isSDET}
                stepComponent={data.stepComponent[index] || INIT_STEP_COMPONENT}
                handleActivityChange={handleActivityChange}
                handleCommonFormChange={handleCommonFormChange}
                handleImpositionChange={handleImpositionChange}
                handleSetStep={handleSetStep}
                handleStatusFormChange={handleStatusFormChange}
              />
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/*
      <CommonForm
        state={simulationState}
        handleFormChange={handleCommonFormChange}
        handleImpositionChange={handleImpositionChange}
      />

      {Boolean(simulationState.impositionChoice) ? (
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          <div className={classes.fieldsContainer}>
            <CustomSelect
              items={activityListItems}
              name="activityTypeId"
              label="Type d'Activité"
              value={simulationState.activityTypeId}
              handleChange={handleActivityChange}
              helperText="Veuillez choisir le type d'activité"
            />
          </div>
          <div className={classes.fieldsContainer}>
            <CustomSelect
              items={filteredStatusForm}
              name="statusFormId"
              label="Forme Juridique"
              value={simulationState.statusFormId}
              handleChange={handleStatusFormChange}
              helperText="Veuillez choisir la forme juridique"
            />
          </div>
        </Box>
      ) : null}

      {Boolean(simulationState.statusFormId) ? (
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          {isSAorSAU ? (
            <div className={classes.fieldsContainer}>
              {isSA ? (
                <CustomNumberField
                  label="Nombre d'Associé"
                  name="nbAssociate"
                  value={`${simulationState.nbAssociate}`}
                  handleChange={handleCommonFormChange}
                  minValue={minNbAssociate}
                  helperText={`La valeur minimum acceptée est ${minNbAssociate}`}
                />
              ) : null}
            </div>
          ) : (
            <div className={classes.radioContainer}>
              <CustomGroupRadio
                isHorizontal={true}
                items={YES_NO_ITEMS}
                name="isStatutoryManager"
                label="Gérant Statutaire"
                value={simulationState.isStatutoryManager}
                handleChange={handleCommonFormChange}
              />
            </div>
          )}
        </Box>
      ) : null}

      <CustomGroupRadio
        isHorizontal={true}
        items={YES_NO_ITEMS}
        name="isMandataire"
        label="Mandataire"
        value={simulationState.isMandataire}
        handleChange={handleCommonFormChange}
      /> */}
    </Box>
  )
}

export default Questions
