import { Button, Theme } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/styles'
import React, { ComponentProps, FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))

const PreviousButton: FC<ComponentProps<any> & ButtonProps> = props => {
  const { children } = props

  const classes = useStyles()

  return (
    <Button
      {...props}
      variant="contained"
      className={classes.root}
      startIcon={<ArrowBackIcon />}
      color="primary"
    >
      {children}
    </Button>
  )
}

export default PreviousButton
