import Box from '@material-ui/core/Box'
import React, { FC } from 'react'
import NextButton from '../../../../customButtons/nextButton'
import PreviousButton from '../../../../customButtons/previousButton'

interface NavigationButtonProps {
  hidePrevious?: boolean
  hideNext?: boolean
  disableNext?: boolean
  handlePrevious?: () => void
  handleNext?: () => void
}

const NavigationButton: FC<NavigationButtonProps> = props => {
  const {
    hidePrevious,
    hideNext,
    disableNext,
    handlePrevious,
    handleNext,
  } = props

  return (
    <Box display="flex" mt={2}>
      {hidePrevious ? null : (
        <Box mr={2}>
          <PreviousButton onClick={handlePrevious}>Précédent</PreviousButton>
        </Box>
      )}
      {hideNext ? null : (
        <NextButton disabled={disableNext} onClick={handleNext}>
          Suivant
        </NextButton>
      )}
    </Box>
  )
}

export default NavigationButton
