import { graphql, useStaticQuery } from 'gatsby'

export interface IntroductionParagraphData {
  id: string
  title_fr: string | null
  title_mg: string | null
  description_fr: string | null
  description_mg: string | null
}

export interface StepFieldComponentData {
  id: string
  label_fr: string | null
  label_mg: string | null
}

export interface StepComponentData {
  id: string
  stepDescritpion_fr: string | null
  stepTitle_fr: string | null
  fieldComponent: StepFieldComponentData[]
}

export interface FeeWarningData {
  id: string
  title_fr: string | null
  title_mg: string | null
  description_fr: string | null
  description_mg: string | null
}

export interface SimulationFeeData {
  introductionParagraph: IntroductionParagraphData
  stepComponent: StepComponentData[]
  recapMessage_fr: string | null
  warning: FeeWarningData[]
}

export default (): SimulationFeeData => {
  const data = useStaticQuery(graphql`
    {
      strapiFeeSimulation {
        introductionParagraph {
          id
          title_fr
          description_fr
          title_mg
          description_mg
        }
        stepComponent {
          stepDescritpion_fr
          stepTitle_fr
          id
          fieldComponent {
            id
            label_fr
            label_mg
          }
        }
        recapMessage_fr
        warning {
          id
          title_fr
          title_mg
          description_fr
          description_mg
        }
      }
    }
  `)

  return data.strapiFeeSimulation
}
