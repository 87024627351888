export enum SimulationStepEnum {
  IMPOSITION = 0,
  ACTIVITY = 1,
  STATUS = 2,
  ASSOCIATE = 3,
  MORAL_PERSON_ASSOCIATE = 4,
  MANAGER = 5,
  CAPITAL = 6,
  BAIL_AMOUNT = 7,
  BAIL_DURATION = 8,
  BAILLEUR_TYPE = 9,
  IS_MANDATAIRE = 10,
}
