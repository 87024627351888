import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react'
import { SelectItem } from '../../../interfaces/common'
import { FormProps } from '../../../interfaces/form'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  label: {
    color: '#828282',
  },
}))

interface CustomGroupRadioProps {
  name: string
  value: string
  items: SelectItem[]
  label?: string
  isMandatory?: boolean
  helperText?: string
  beginTyping?: boolean
  disabled?: boolean
  isHorizontal?: boolean
  handleChange(payload: FormProps): void
}

const CustomGroupRadio: FC<CustomGroupRadioProps> = props => {
  const {
    name,
    value,
    items,
    label,
    isMandatory,
    helperText,
    beginTyping,
    disabled,
    isHorizontal,
    handleChange,
  } = props
  const classes = useStyles()

  const [localValue, setLocalValue] = useState(props.value)
  const [beginSelect, setBeginSelect] = useState(props.beginTyping || false)

  const isError = isMandatory && beginSelect && !value.length

  useEffect(() => {
    setBeginSelect(props.beginTyping || false)
    setLocalValue(props.value)
  }, [props.beginTyping, props.value])

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event
    setBeginSelect(true)
    setLocalValue(value)
    handleChange({ name, value })
  }

  return (
    <Box>
      {label ? (
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      ) : null}
      <FormControl
        component="fieldset"
        error={isError}
        className={classes.formControl}
      >
        {/* FormLabel blink while changing other group sharing same state ?! */}
        {/* {label ? <FormLabel component="legend">{label}</FormLabel> : null} */}

        <RadioGroup
          aria-label={name}
          name={name}
          value={localValue}
          onChange={handleRadioChange}
          row={isHorizontal}
        >
          {items.map(i => (
            <Fragment key={`radio-${i.value}`}>
              {i.label && i.value ? (
                <FormControlLabel
                  value={i.value}
                  control={<Radio />}
                  label={i.label}
                  disabled={disabled}
                />
              ) : null}
            </Fragment>
          ))}
        </RadioGroup>
        {isError ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </Box>
  )
}

export default CustomGroupRadio
