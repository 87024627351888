import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { FC, useEffect, useState } from 'react'
import { SelectItem } from '../../../interfaces/common'
import { FormProps } from '../../../interfaces/form'

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { isSmall: boolean }) =>
    props.isSmall
      ? {
          '& select': {
            padding: theme.spacing(1),
            color: theme.palette.primary.main,
          },
          '& fieldset': {
            borderColor: theme.palette.primary.main,
            '&:hover': {
              borderColor: theme.palette.primary.main,
            },
          },
        }
      : {},
}))

interface CustomSelectProps {
  name: string
  value: string
  items: SelectItem[]
  label?: string
  isSmall?: boolean
  isMandatory?: boolean
  helperText?: string
  beginTyping?: boolean
  disabled?: boolean
  handleChange(payload: FormProps): void
}

const CustomSelect: FC<CustomSelectProps> = props => {
  const { items, label, isMandatory, helperText, disabled } = props
  const isSmall = props.isSmall || false
  const classes = useStyles({ isSmall })
  const [localValue, setLocalValue] = useState(props.value)
  const [beginSelect, setBeginSelect] = useState(props.beginTyping || false)

  const isError = isMandatory && !props.value.length

  useEffect(() => {
    setBeginSelect(props.beginTyping || false)
    setLocalValue(props.value)
  }, [props.beginTyping, props.value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event
    setBeginSelect(true)
    setLocalValue(value)
    props.handleChange({ name, value })
  }

  return (
    <form noValidate={true} autoComplete="off">
      <TextField
        className={classes.root}
        label={label}
        name={props.name}
        select={true}
        value={localValue}
        onChange={handleChange}
        SelectProps={{
          native: true,
        }}
        variant="outlined"
        fullWidth={!isSmall}
        error={beginSelect && isError}
        helperText={beginSelect && isError && helperText}
        disabled={disabled}
      >
        {items.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </form>
  )
  // return null
}

export default CustomSelect
